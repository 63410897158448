import React, { useEffect, useContext, useRef } from "react";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Clients from "../../components/common/Clients";
import InteractiveVideo from "../../components/common/InteractiveVideo";
// import image from "../../assets/img/products/Organisational change projects main.jpg";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";
import { StaticImage } from "gatsby-plugin-image";

const OrganizationalChangeProjects = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Organisational Change Projects | VIRSAT - Virtual Reality Safety Training'
        description='Culture change is a result of sustained effort. VIRSAT has the experience to implement programs to create change towards world-class safety performance.'
      />
      <PageBanner
        title='Organisational Change Projects'
        subtitle='Program to change culture'
      />
      <section
        className='main-container single-product hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col'>
          <div className='column center fj-center'>
            <div className='image-container'>
              <StaticImage
                src='../../assets/img/products/Organisational change projects main.jpg'
                alt='Organisational Change Projects'
                placeholder='blurred'
                objectFit
              />
              {/* <img src={image} alt='Pre-job discussions' /> */}
            </div>
            <p className='reduce_width no-mar' style={{ marginTop: "30px" }}>
              Culture change is a result of sustained effort. VIRSAT has the
              experience to implement programs to create change towards
              world-class safety performance.
            </p>
          </div>
        </div>
      </section>
      <Clients />
      <InteractiveVideo />
    </Layout>
  );
};

export default OrganizationalChangeProjects;
